import React from "react";
import { useParams, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import serviceCatalog from "../serviceCatalog.json";
import CallNow from "./common/CallNow";
import { SEO_KEYWORDS, PAGE_TITLES, ROUTES } from "../utils/config";

const ServicePage = () => {
  const { serviceId } = useParams();
  const service = serviceCatalog.services.find((s) => s.id === serviceId);

  console.log("Service ID from URL:", serviceId); // Debugging line
  console.log("Service object found:", service); // Debugging line

  if (!service) {
    return (
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-4">Service Not Found</h1>
        <p>Sorry, we couldn't find the service with ID: {serviceId}</p>
        <Link to="/services" className="text-blue-600 hover:underline">
          Return to Services
        </Link>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <Helmet>
        <title>{`${service.title} | Aim High Electric Services`}</title>
        <meta name="description" content={service.shortDescription} />
        <meta name="keywords" content={service.seoKeywords.join(", ")} />
      </Helmet>

      <h1 className="text-3xl font-bold mb-4">{service.title}</h1>
      <p className="text-xl mb-6">{service.shortDescription}</p>

      <div className="bg-gray-100 p-6 rounded-lg mb-8">
        <h2 className="text-2xl font-semibold mb-4">Service Details</h2>
        <p>{service.longDescription}</p>
        <div></div>
        <CallNow text={`Call about ${service.title}`} />{" "}
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">The Aim High Advantage</h2>
        <ul className="list-disc pl-6">
          {service.keyPoints.map((point, index) => (
            <li key={index} className="mb-2">
              {point}
            </li>
          ))}
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Service Locations</h2>
        <ul className="flex flex-wrap gap-2">
          {service.locations.map((location, index) => (
            <li
              key={index}
              className="bg-blue-100 text-blue-800 px-3 py-1 rounded"
            >
              {location}
            </li>
          ))}
        </ul>
      </div>

      <div>
        <h2 className="text-2xl font-semibold mb-4">Related Services</h2>
        <ul className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {service.relatedServices.map((relatedServiceId, index) => {
            const relatedService = serviceCatalog.services.find(
              (s) => s.id === relatedServiceId
            );
            return relatedService ? (
              <li key={index} className="bg-white shadow rounded p-4">
                <Link
                  to={`${ROUTES.SERVICES}/${relatedServiceId}`}
                  className="text-blue-600 hover:underline"
                >
                  {relatedService.title}
                </Link>
              </li>
            ) : null;
          })}
        </ul>
      </div>
    </div>
  );
};

export default ServicePage;

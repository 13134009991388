import {
  React,
  useState,
  Battery,
  Zap,
  Shield,
  Wrench,
  AlertTriangle,
  Clock,
  ChevronDown,
} from "../commonImports"; // Consolidate all common imports into one
import CallNow from "../common/CallNow";
import ServiceAccordion from "../common/ServiceAccordion";
import Sidebar from "../layout/Sidebar";

const GeneratorRecommendationTool = () => {
  const [homeSize, setHomeSize] = useState("");
  const [essentialAppliances, setEssentialAppliances] = useState([]);
  const [budget, setBudget] = useState("");
  const [recommendation, setRecommendation] = useState(null);

  const appliances = [
    { name: "Refrigerator", wattage: 600 },
    { name: "HVAC System", wattage: 3500 },
    { name: "Sump Pump", wattage: 800 },
    { name: "Well Pump", wattage: 1000 },
    { name: "Electric Water Heater", wattage: 4000 },
    { name: "Lighting", wattage: 500 },
    { name: "Microwave", wattage: 1000 },
    { name: "Computer/TV", wattage: 400 },
  ];

  const handleAppliance = (applianceName) => {
    if (essentialAppliances.includes(applianceName)) {
      setEssentialAppliances(
        essentialAppliances.filter((a) => a !== applianceName)
      );
    } else {
      setEssentialAppliances([...essentialAppliances, applianceName]);
    }
  };

  const getRecommendation = () => {
    let totalWattage = essentialAppliances.reduce((sum, applianceName) => {
      const appliance = appliances.find((a) => a.name === applianceName);
      return sum + (appliance ? appliance.wattage : 0);
    }, 0);

    let recommendedSize;
    let model;

    if (homeSize === "small" && totalWattage < 7500) {
      recommendedSize = "8kW - 11kW";
      model = "Generac Guardian 8kW - 11kW Home Backup Generator";
    } else if (
      (homeSize === "small" && totalWattage >= 7500) ||
      (homeSize === "medium" && totalWattage < 12000)
    ) {
      recommendedSize = "12kW - 16kW";
      model = "Generac Guardian 12kW - 16kW Home Backup Generator";
    } else if (
      (homeSize === "medium" && totalWattage >= 12000) ||
      (homeSize === "large" && totalWattage < 20000)
    ) {
      recommendedSize = "18kW - 22kW";
      model = "Generac Guardian 18kW - 22kW Home Backup Generator";
    } else {
      recommendedSize = "24kW - 30kW";
      model = "Generac Guardian 24kW - 30kW Home Backup Generator";
    }

    setRecommendation({
      size: recommendedSize,
      model: model,
      wattage: totalWattage,
    });
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md mb-8">
      <h3 className="text-2xl font-bold mb-4">
        Generator Size Recommendation Tool
      </h3>
      <div className="relative">
        <select
          value={homeSize}
          onChange={(e) => setHomeSize(e.target.value)}
          className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        >
          <option value="">Select home size</option>
          <option value="small">Small (Up to 1,500 sq ft)</option>
          <option value="medium">Medium (1,500 - 3,000 sq ft)</option>
          <option value="large">Large (Over 3,000 sq ft)</option>
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <ChevronDown size={20} />
        </div>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2">
          Essential Appliances:
        </label>
        <div className="grid grid-cols-2 gap-2">
          {appliances.map((appliance) => (
            <label key={appliance.name} className="flex items-center">
              <input
                type="checkbox"
                checked={essentialAppliances.includes(appliance.name)}
                onChange={() => handleAppliance(appliance.name)}
                className="mr-2"
              />
              {appliance.name}
            </label>
          ))}
        </div>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2">Budget:</label>
        <div className="relative">
          <select
            value={budget}
            onChange={(e) => setBudget(e.target.value)}
            className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          >
            <option value="">Select budget range</option>
            <option value="low">$3,000 - $5,000</option>
            <option value="medium">$5,000 - $8,000</option>
            <option value="high">$8,000+</option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <ChevronDown size={20} />
          </div>
        </div>
      </div>
      <button
        onClick={getRecommendation}
        className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300"
      >
        Get Recommendation
      </button>
      {recommendation && (
        <div className="mt-4 p-4 bg-blue-100 rounded">
          <h4 className="font-bold text-lg mb-2">Recommended Generator:</h4>
          <p>Size: {recommendation.size}</p>
          <p>Model: {recommendation.model}</p>
          <p>Estimated Wattage Needs: {recommendation.wattage} watts</p>
          <p className="mt-2 text-sm text-gray-600">
            Note: This is a general recommendation. Please consult with our
            experts for a personalized assessment.
          </p>

          <CallNow
            phoneNumber="(832) 925-4669"
            text={`Let's install ${recommendation.model} for you`}
          />
        </div>
      )}
    </div>
  );
};

const GeneratorsPage = ({ city }) => {
  return (
    <div className="min-h-screen bg-gray-100">
      <main className="container mx-auto py-8 sm:py-12 px-4">
        <div className="flex flex-col lg:flex-row lg:space-x-8">
          {/* Main Content */}
          <div className="lg:w-2/3">
            <h1 className="text-3xl sm:text-4xl font-bold mb-6 text-blue-800">
              Reliable Backup Generators in {city}: Powering Your Peace of Mind
            </h1>

            <p className="mb-6 text-lg">
              Don't let {city}'s unpredictable weather leave you in the dark.
              Aim High Electric Services provides top-tier backup generator
              solutions to keep your home or business powered, no matter what
              nature throws your way.
            </p>

            {/* Generator Recommendation Tool */}
            <section className="mb-12">
              <h2 className="text-2xl sm:text-3xl font-bold mb-4 text-blue-700">
                Find Your Perfect Generator
              </h2>
              <GeneratorRecommendationTool />
            </section>

            <section className="mb-12">
              <h2 className="text-2xl sm:text-3xl font-bold mb-4 text-blue-700">
                Why {city} Residents Need Backup Generators
              </h2>
              <ul className="list-none space-y-4">
                <li className="flex items-start">
                  <Zap className="mr-2 text-yellow-600 flex-shrink-0" />
                  <span>
                    Protect against frequent power outages due to severe weather
                    in {city}
                  </span>
                </li>
                <li className="flex items-start">
                  <Shield className="mr-2 text-blue-600 flex-shrink-0" />
                  <span>
                    Ensure safety and security for your family during
                    emergencies
                  </span>
                </li>
                <li className="flex items-start">
                  <Battery className="mr-2 text-green-600 flex-shrink-0" />
                  <span>
                    Maintain business operations and prevent financial losses
                  </span>
                </li>
              </ul>
            </section>

            <section className="mb-12">
              <h2 className="text-2xl sm:text-3xl font-bold mb-4 text-blue-700">
                Our Comprehensive Generator Services in {city}
              </h2>
              <ul className="list-disc pl-6 space-y-2">
                <li>
                  Expert generator installation tailored to {city}'s unique
                  climate
                </li>
                <li>
                  24/7 repair services to keep your generator running when you
                  need it most
                </li>
                <li>
                  Regular maintenance to ensure optimal performance and
                  longevity
                </li>
                <li>
                  Upgrades and replacements for aging or undersized systems
                </li>
              </ul>
            </section>

            <section className="mb-12">
              <h2 className="text-2xl sm:text-3xl font-bold mb-4 text-blue-700">
                Signs You Need Generator Replacement in {city}
              </h2>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <ul className="list-none space-y-4">
                  <li className="flex items-start">
                    <AlertTriangle className="mr-2 text-red-600 flex-shrink-0" />
                    <span>
                      Insufficient power output for your {city} home's needs
                    </span>
                  </li>
                  <li className="flex items-start">
                    <AlertTriangle className="mr-2 text-red-600 flex-shrink-0" />
                    <span>
                      Frequent power fluctuations or reliability issues
                    </span>
                  </li>
                  <li className="flex items-start">
                    <AlertTriangle className="mr-2 text-red-600 flex-shrink-0" />
                    <span>Slow startup or repeated failure to start</span>
                  </li>
                  <li className="flex items-start">
                    <AlertTriangle className="mr-2 text-red-600 flex-shrink-0" />
                    <span>Increasing frequency of repairs</span>
                  </li>
                  <li className="flex items-start">
                    <AlertTriangle className="mr-2 text-red-600 flex-shrink-0" />
                    <span>
                      Generator age exceeding 10-15 years with regular use
                    </span>
                  </li>
                </ul>
              </div>
            </section>

            {/* ServiceAccordion Section */}
            <section className="mb-12">
              <h2 className="text-2xl sm:text-3xl font-bold mb-6 text-blue-700">
                Frequently Asked Questions About Generac Generators in {city}
              </h2>
              <div className="bg-white shadow-md rounded-lg overflow-hidden mb-12">
                <ServiceAccordion
                  title={`What size Generac generator do I need for my ${city} home?`}
                >
                  <p>
                    The size of the Generac generator you need depends on your
                    specific power requirements. Generally, a 5,000 to
                    8,500-watt generator can power essential appliances, while a
                    10,000 to 15,000-watt generator can handle most household
                    needs. We recommend a professional assessment to determine
                    the right size for your ${city} home.
                  </p>
                </ServiceAccordion>
                <ServiceAccordion title="How often should I service my Generac generator?">
                  <p>
                    Generac recommends servicing your generator at least once a
                    year or after every 200 hours of use, whichever comes first.
                    Regular maintenance ensures optimal performance and
                    longevity, especially important in areas prone to frequent
                    power outages.
                  </p>
                </ServiceAccordion>
                <ServiceAccordion
                  title={`Are Generac generators noisy? Will it disturb my neighbors in ${city}?`}
                >
                  <p>
                    Generac generators are designed to operate quietly,
                    typically producing around 60-70 decibels at 23 feet –
                    similar to normal conversation. The noise level is generally
                    acceptable in most ${city} neighborhoods, but it's always
                    good to check local noise ordinances.
                  </p>
                </ServiceAccordion>
                <ServiceAccordion title="How long can a Generac generator run continuously?">
                  <p>
                    Generac whole house generators can run continuously for as
                    long as you have a fuel supply. Natural gas generators can
                    run indefinitely, while propane generators can run for
                    several days to weeks, depending on the tank size and load.
                  </p>
                </ServiceAccordion>
                <ServiceAccordion
                  title={`Can a Generac generator power my entire ${city} home?`}
                >
                  <p>
                    Yes, with proper sizing, a Generac whole house generator can
                    power your entire ${city} home. However, many homeowners
                    choose to power only essential circuits to reduce costs. We
                    can help you determine the best solution for your needs and
                    budget.
                  </p>
                </ServiceAccordion>
              </div>
            </section>
          </div>

          {/* Sidebar with Form and Offer */}
          {/* Sidebar */}
          <Sidebar city={city} />
        </div>
      </main>
    </div>
  );
};

export default GeneratorsPage;

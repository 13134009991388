import React from 'react';
import Header from '../layout/Header';
import { Battery, Zap, Shield, Wrench } from 'lucide-react';

const KatyGeneratorsPage = ({ setCurrentPage }) => {
  return (
    <div className="min-h-screen bg-gray-100">

      <main className="container mx-auto py-8 sm:py-12 px-4">
        <h1 className="text-3xl sm:text-4xl font-bold mb-6 text-blue-800">Generac Generators in Katy, TX: Power Solutions for Every Home</h1>
        
        <p className="mb-6 text-lg">Don't let Katy's unpredictable weather leave you in the dark. Aim High Electric Services provides top-tier Generac generator installation, repair, and maintenance services to keep your Katy home powered, no matter what nature throws your way.</p>

        <section className="mb-12">
          <h2 className="text-2xl sm:text-3xl font-bold mb-4 text-blue-700">Why Choose Generac Generators for Your Katy Home?</h2>
          <ul className="list-none space-y-4">
            <li className="flex items-start">
              <Battery className="mr-2 text-green-600 flex-shrink-0" />
              <span>Reliable power during Katy's frequent storms and outages</span>
            </li>
            <li className="flex items-start">
              <Shield className="mr-2 text-blue-600 flex-shrink-0" />
              <span>Protect your home and appliances from power surges common in Katy's grid</span>
            </li>
            <li className="flex items-start">
              <Wrench className="mr-2 text-red-600 flex-shrink-0" />
              <span>Local Katy technicians for prompt service and maintenance</span>
            </li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl sm:text-3xl font-bold mb-4 text-blue-700">Our Generac Services in Katy</h2>
          <div className="grid md:grid-cols-2 gap-6">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-2">Installation</h3>
              <p>Expert installation of Generac generators, tailored to Katy's unique climate and power needs.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-2">Repair</h3>
              <p>Swift, efficient repairs to get your Generac generator back online quickly, essential during Katy's storm season.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-2">Maintenance</h3>
              <p>Regular service to ensure your Generac generator is always ready for Katy's unpredictable weather.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-2">Upgrades</h3>
              <p>Enhance your existing Generac system to meet Katy's evolving power demands.</p>
            </div>
          </div>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl sm:text-3xl font-bold mb-4 text-blue-700">Why Katy Homeowners Trust Us</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li>Local Katy expertise in Generac generator systems</li>
            <li>24/7 emergency services tailored for Katy's needs</li>
            <li>Competitive pricing with financing options for Katy residents</li>
            <li>Fully licensed and insured for work in Katy, TX</li>
          </ul>
        </section>

        <div className="bg-blue-100 p-6 rounded-lg mb-8 text-center">
          <p className="text-xl font-semibold mb-4">Ready to secure your Katy home's power supply?</p>
          <a href="/contact-us/" className="bg-red-600 text-white px-6 py-3 rounded-lg hover:bg-red-700 transition duration-300 inline-block">Get Your Free Generac Estimate in Katy Today!</a>
        </div>
      </main>

      <footer className="bg-blue-800 text-white py-8">
        <div className="container mx-auto text-center px-4">
          <p>&copy; 2024 Aim High Electric Services. Proudly serving Katy, TX and surrounding areas.</p>
          <p className="mt-2">Licensed and Insured | Authorized Generac Generator Installers in Katy</p>
        </div>
      </footer>
    </div>
  );
};

export default KatyGeneratorsPage;
import React from 'react';
import { Clock } from 'lucide-react';
import CallNow from '../common/CallNow';

const Sidebar = ({ city }) => {
  return (
    <div className="lg:w-1/3 mt-8 lg:mt-0">
      <div className="lg:sticky lg:top-8">
        {/* Limited Time Offer */}
        <div className="bg-blue-100 p-6 rounded-lg mb-8">
          <h3 className="text-xl font-bold mb-4 flex items-center">
            <Clock className="mr-2 text-blue-600" />
            Limited Time Offer
          </h3>
          <p className="text-lg mb-4">Get a 20kW Whole Home Generator</p>
          <p className="text-3xl font-bold text-red-600 mb-4">$8,999 Installed</p>
          <p className="mb-4">Includes standard installation and 5-year warranty</p>
          <p className="text-sm text-gray-600">*Offer valid for {city} residents. Terms and conditions apply.</p>
          <div className="mt-4">
            <CallNow phoneNumber="(832) 925-4669" text="Claim this offer now!" />
          </div>
        </div>

        {/* Form */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-xl font-bold mb-4">Get Your Free Estimate</h3>
          <form>
            <div className="mb-4">
              <label htmlFor="name" className="block text-gray-700 font-bold mb-2">Name</label>
              <input type="text" id="name" name="name" className="w-full px-3 py-2 border rounded-lg" />
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700 font-bold mb-2">Email</label>
              <input type="email" id="email" name="email" className="w-full px-3 py-2 border rounded-lg" />
            </div>
            <div className="mb-4">
              <label htmlFor="phone" className="block text-gray-700 font-bold mb-2">Phone</label>
              <input type="tel" id="phone" name="phone" className="w-full px-3 py-2 border rounded-lg" />
            </div>
            <button type="submit" className="w-full bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700 transition duration-300">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
import React from "react";
import { Phone } from "lucide-react";
import { COMPANY_PHONE } from "../../utils/config";
const CallNow = ({ text = "Need help? Call now!" }) => (
  <div className="pt-4">
    <div className="bg-red-600 text-white p-4 rounded-lg flex items-center justify-between">
      <span className="font-bold text-lg">{text}</span>
      <a
        href={`tel:${COMPANY_PHONE}`}
        className="flex items-center bg-white text-red-600 px-4 py-2 rounded-lg hover:bg-red-100 transition duration-300"
      >
        <Phone size={20} className="mr-2" />
        <span className="font-bold">{COMPANY_PHONE}</span>
      </a>
    </div>
  </div>
);

export default CallNow;

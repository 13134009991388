import React, { useState, useRef } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const ServiceAccordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-b border-gray-200">
      <button
        className="flex justify-between items-center w-full py-4 px-6 text-left transition-colors duration-300 hover:bg-gray-50"
        onClick={toggleAccordion}
      >
        <span className="text-lg font-semibold">{title}</span>
        <span className={`transform transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`}>
          <ChevronDown size={24} />
        </span>
      </button>
      <div
        ref={contentRef}
        className={`overflow-hidden transition-all duration-300 ease-in-out ${
          isOpen ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'
        }`}
      >
        <div className="p-6 bg-gray-50">{children}</div>
      </div>
    </div>
  );
};

export default ServiceAccordion;
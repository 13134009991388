// src/components/layout/Footer.js
import { React } from "../commonImports";

const Footer = () => {
  return (
    <footer className="bg-blue-800 text-white py-8">
      <div className="container mx-auto text-center px-4">
        <p>
          &copy; {new Date().getFullYear()} Aim High Electric Services. All
          rights reserved.
        </p>
        <p className="mt-2">
          Serving Houston, Katy, and surrounding areas with pride.
        </p>
      </div>
    </footer>
  );
};

export default Footer;

// src/config.js

export const COMPANY_PHONE = "281-748-9700";

export const COMPANY_NAME = "Aim High Electric Services";

// Add other configuration variables as needed
export const COMPANY_EMAIL = "info@aimhighelectric.com";
export const COMPANY_ADDRESS = "123 Main St, Katy, TX 77450";

export const ROUTES = {
  HOME: "/",
  SERVICES: "/katy-electrical-services",
  GENERATORS: "/katy-generators-installs",
  KATY_GENERATORS: "/katy-generators-installs-repairs",
  HOUSTON_GENERATORS: "/houston-generators-installs-repairs",
  ABOUT: "/about-katy-aim-high-electrician",
  CONTACT: "/contact",
};

export const PAGE_TITLES = {
  HOME: "Home",
  SERVICES: "Our Services",
  GENERATORS: "Generators",
  KATY_GENERATORS: "Generators in Katy",
  HOUSTON_GENERATORS: "Generators in Houston",
  ABOUT: "About Us",
  CONTACT: "Contact Us",
};

export const SEO_KEYWORDS = {
  HOME: "electrical services, Katy, Houston, home electrical",
  SERVICES: "electrical services, repairs, installations, Katy, Houston",
  GENERATORS: "backup generators, Generac, power solutions, Katy, Houston",
  KATY_GENERATORS: "generators Katy, backup power Katy, Generac Katy",
  HOUSTON_GENERATORS:
    "generators Houston, backup power Houston, Generac Houston",
  ABOUT:
    "electrical company, about us, Katy electricians, Houston electricians",
  CONTACT:
    "contact electrician, Katy electrical services, Houston electrical services",
};

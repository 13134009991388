// src/components/commonImports.js

import React, { useState, useEffect, useRef } from "react";
import {
  Battery,
  Zap,
  Shield,
  Wrench,
  AlertTriangle,
  Clock,
  ChevronDown,
  Phone,
  Calendar,
  Star,
  Menu,
  X,
} from "lucide-react";

export {
  React,
  useState,
  useEffect,
  useRef,
  Battery,
  Zap,
  Shield,
  Wrench,
  AlertTriangle,
  Clock,
  ChevronDown,
  Phone,
  Calendar,
  Star,
  Menu,
  X,
};

const CommonImports = {
  React,
  useState,
  useEffect,
  useRef,
  Battery,
  Zap,
  Shield,
  Wrench,
  AlertTriangle,
  Clock,
  ChevronDown,
  Phone,
  Calendar,
  Star,
  Menu,
  X,
};

export default CommonImports;

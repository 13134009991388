import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Menu, X, Phone, Calendar, ChevronDown } from "lucide-react";
import {
  COMPANY_NAME,
  COMPANY_PHONE,
  ROUTES,
  PAGE_TITLES,
} from "../../utils/config";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isGeneratorsDropdownOpen, setIsGeneratorsDropdownOpen] =
    useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleGeneratorsDropdown = () =>
    setIsGeneratorsDropdownOpen(!isGeneratorsDropdownOpen);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`w-full ${
        isSticky ? "fixed top-0 left-0 right-0 shadow-md" : ""
      } transition-all duration-300 z-50`}
    >
      {/* Top Bar */}
      <div className="bg-blue-800 text-white py-2 px-4">
        <div className="container mx-auto flex justify-end items-center space-x-4">
          <button className="flex items-center bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition-colors">
            <Calendar size={20} className="mr-2" />
            Schedule Service
          </button>
          <a
            href={`tel:${COMPANY_PHONE}`}
            className="flex items-center hover:text-gray-200 transition-colors"
          >
            <Phone size={20} className="mr-2" />
            <span className="font-bold">{COMPANY_PHONE}</span>
          </a>
        </div>
      </div>

      {/* Main Header */}
      <header className="bg-white">
        <div className="container mx-auto flex justify-between items-center py-4 px-4">
          <div className="flex items-center">
            <img
              src="https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/PGJ6yXJE83LH67VM0to0/media/64f395c40104b5095ecb1d58.svg+xml"
              alt="Aim High Electric Services"
              className="h-12 w-auto"
            />
            <span className="sr-only">{COMPANY_NAME}</span>
          </div>
          <button className="lg:hidden z-50" onClick={toggleMenu}>
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
          <nav
            className={`${
              isMenuOpen ? "block" : "hidden"
            } lg:block absolute top-full left-0 right-0 bg-white lg:relative lg:top-auto lg:left-auto lg:right-auto shadow-md lg:shadow-none`}
          >
            <ul className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-6 p-4 lg:p-0">
              <li>
                <Link
                  to={ROUTES.HOME}
                  className="text-gray-600 hover:text-red-600 block"
                >
                  {PAGE_TITLES.HOME}
                </Link>
              </li>
              <li>
                <Link
                  to={ROUTES.SERVICES}
                  className="text-gray-600 hover:text-red-600 block"
                >
                  {PAGE_TITLES.SERVICES}
                </Link>
              </li>
              <li>
                <Link
                  to={ROUTES.GENERATORS}
                  className="text-gray-600 hover:text-red-600 block"
                >
                  {PAGE_TITLES.GENERATORS}
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </div>
  );
};

export default Header;

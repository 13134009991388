import React from 'react';
import { Star } from 'lucide-react';

const ReviewCard = ({ author, rating, text }) => (
  <div className="bg-white p-6 rounded-lg shadow-md">
    <div className="flex items-center mb-4">
      <div className="w-12 h-12 bg-gray-300 rounded-full mr-4"></div>
      <div>
        <h4 className="font-semibold">{author}</h4>
        <div className="flex">
          {[...Array(5)].map((_, i) => (
            <Star
              key={i}
              size={16}
              className={i < rating ? "text-yellow-400 fill-current" : "text-gray-300"}
            />
          ))}
        </div>
      </div>
    </div>
    <p className="text-gray-600">{text}</p>
  </div>
);

const GoogleReviews = () => {
  // This would be replaced with actual data from the Google Places API
  const reviews = [
    { author: "John Doe", rating: 5, text: "Excellent service! They installed my Generac generator quickly and professionally." },
    { author: "Jane Smith", rating: 4, text: "Very responsive team. Fixed our electrical issue on the same day." },
    { author: "Mike Johnson", rating: 5, text: "Great experience with their EV charger installation. Highly recommend!" },
  ];

  return (
    <section className="py-12 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">What Our Customers Say</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {reviews.map((review, index) => (
            <ReviewCard key={index} {...review} />
          ))}
        </div>
        <div className="text-center mt-8">
          <a 
            href="https://www.google.com/maps/place/Aimhigh+Electricians+of+Katy/" 
            target="_blank" 
            rel="noopener noreferrer"
            className="inline-block bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition duration-300"
          >
            See All Reviews on Google
          </a>
        </div>
      </div>
    </section>
  );
};

export default GoogleReviews;
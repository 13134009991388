import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faPlug,
  faLightbulb,
  faHome,
  faIndustry,
  faTools,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import serviceCatalog from "../../serviceCatalog.json";
import { SEO_KEYWORDS, PAGE_TITLES, ROUTES } from "../../utils/config";
import { Helmet } from "react-helmet";
// Map service IDs to Font Awesome icons
const serviceIcons = {
  "generator-installation": faBolt,
  "generator-maintenance": faTools,
  "generator-repair": faWrench,
  "electrical-panel-upgrade": faPlug,
  "home-rewiring": faHome,
  "bathroom-kitchen-lighting": faLightbulb,
  // Add more mappings as needed
};

const ServicesPage = () => {
  return (
    <>
      <Helmet>
        <title>{PAGE_TITLES.SERVICES} | Aim High Electric Services</title>
        <meta
          name="description"
          content="Professional electrical services in Katy and Houston."
        />
        <meta name="keywords" content={SEO_KEYWORDS.SERVICES} />
      </Helmet>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-8">Our Services</h1>

        <div className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">
            <FontAwesomeIcon icon={faBolt} className="mr-2 text-red-600" />
            Generator Services
          </h2>
          <ul className="list-disc pl-6">
            <li>
              <Link to="/generators" className="text-blue-600 hover:underline">
                <FontAwesomeIcon icon={faBolt} className="mr-2 text-red-600" />
                All Generator Services
              </Link>
            </li>
            <li>
              <Link
                to="/katy-generators"
                className="text-blue-600 hover:underline"
              >
                <FontAwesomeIcon icon={faBolt} className="mr-2 text-red-600" />
                Generators in Katy
              </Link>
            </li>
            <li>
              <Link
                to="/houston-generators"
                className="text-blue-600 hover:underline"
              >
                <FontAwesomeIcon icon={faBolt} className="mr-2 text-red-600" />
                Generators in Houston
              </Link>
            </li>
          </ul>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">
            <FontAwesomeIcon icon={faIndustry} className="mr-2 text-red-600" />
            Other Electrical Services
          </h2>
          <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {serviceCatalog.services.map((service) => (
              <li key={service.id} className="group">
                <Link to={`${ROUTES.SERVICES}/${service.id}`} className="block">
                  <div className="relative bg-transparent rounded-lg overflow-hidden transition-all duration-300 hover:scale-105">
                    <div className="electrifying-border"></div>
                    <div className="service-content p-6 bg-white">
                      <h3 className="text-xl font-semibold mb-2 flex items-center text-gray-800 group-hover:text-blue-600">
                        <FontAwesomeIcon
                          icon={serviceIcons[service.id] || faIndustry}
                          className="mr-3 text-red-600 text-2xl"
                        />
                        {service.title}
                      </h3>
                      <p className="text-gray-600 mb-4">
                        {service.shortDescription}
                      </p>
                      <div className="text-sm text-gray-500">
                        Servicing: {service.locations.join(", ")}
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ServicesPage;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  HomePage,
  ServicesPage,
  GeneratorsPage,
  KatyGeneratorsPage,
  HoustonGeneratorsPage,
  AboutPage,
  ContactPage,
  ServicePage, // Import ServicePage here
  Header,
  Footer,
} from "./components";
import { ROUTES } from "./utils/config";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path={ROUTES.HOME} element={<HomePage />} />
        <Route path={ROUTES.SERVICES} element={<ServicesPage />} />
        <Route path={ROUTES.GENERATORS} element={<GeneratorsPage />} />
        <Route path={ROUTES.KATY_GENERATORS} element={<KatyGeneratorsPage />} />
        <Route
          path={ROUTES.HOUSTON_GENERATORS}
          element={<HoustonGeneratorsPage />}
        />
        <Route path={ROUTES.ABOUT} element={<AboutPage />} />
        <Route path={ROUTES.CONTACT} element={<ContactPage />} />
        <Route
          path={`${ROUTES.SERVICES}/:serviceId`}
          element={<ServicePage />}
        />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;

import {
  React,
  useState,
  Battery,
  Zap,
  Shield,
  Star,
  Wrench,
  AlertTriangle,
  Clock,
  ChevronDown,
  CallNow,
  ServiceAccordion,
  Sidebar,
} from "../";

const HoustonGeneratorsPage = ({ setCurrentPage }) => {
  return (
    <div className="min-h-screen bg-gray-100">
      <main className="container mx-auto py-8 sm:py-12 px-4">
        <h1 className="text-3xl sm:text-4xl font-bold mb-6 text-blue-800">
          Generac Generators in Houston: Powering the Energy Capital
        </h1>

        <p className="mb-6 text-lg">
          Houston's energy needs are unique, and so are our Generac generator
          solutions. Aim High Electric Services ensures that Houston homes and
          businesses stay powered through hurricanes, heat waves, and everything
          in between.
        </p>

        <section className="mb-12">
          <h2 className="text-2xl sm:text-3xl font-bold mb-4 text-blue-700">
            Why Houston Relies on Generac Generators
          </h2>
          <ul className="list-none space-y-4">
            <li className="flex items-start">
              <Battery className="mr-2 text-green-600 flex-shrink-0" />
              <span>
                Uninterrupted power during Houston's severe weather events
              </span>
            </li>
            <li className="flex items-start">
              <Shield className="mr-2 text-blue-600 flex-shrink-0" />
              <span>
                Protect Houston businesses from costly power-related downtime
              </span>
            </li>
            <li className="flex items-start">
              <Zap className="mr-2 text-yellow-600 flex-shrink-0" />
              <span>
                Houston-based technicians for rapid response and service
              </span>
            </li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl sm:text-3xl font-bold mb-4 text-blue-700">
            Our Generac Services in Houston
          </h2>
          <div className="grid md:grid-cols-2 gap-6">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-2">Installation</h3>
              <p>
                Customized Generac generator installations for Houston's diverse
                residential and commercial needs.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-2">Repair</h3>
              <p>
                24/7 repair services to keep Houston powered during critical
                times.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-2">Maintenance</h3>
              <p>
                Proactive maintenance plans tailored to Houston's climate and
                usage patterns.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-2">
                Commercial Solutions
              </h3>
              <p>
                Specialized Generac systems for Houston's businesses and
                industrial sector.
              </p>
            </div>
          </div>
        </section>

        <div className="bg-blue-100 p-6 rounded-lg mb-8 text-center">
          <p className="text-xl font-semibold mb-4">
            Secure your Houston property's power today!
          </p>
          <a
            href="/contact-us/"
            className="bg-red-600 text-white px-6 py-3 rounded-lg hover:bg-red-700 transition duration-300 inline-block"
          >
            Request Your Houston Generac Consultation Now
          </a>
        </div>
      </main>

      <footer className="bg-blue-800 text-white py-8">
        <div className="container mx-auto text-center px-4">
          <p>
            &copy; 2024 Aim High Electric Services. Serving Houston and the
            Greater Houston area.
          </p>
          <p className="mt-2">
            Licensed and Insured | Premier Generac Generator Installers in
            Houston
          </p>
        </div>
      </footer>
    </div>
  );
};

export default HoustonGeneratorsPage;

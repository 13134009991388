import {
  React,
  useState,
  Phone,
  Calendar,
  Star,
  Menu,
  X,
} from "../commonImports"; // Ensure to import all necessary components
import Header from "../layout/Header";

const HomePage = ({ setCurrentPage }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <div className="min-h-screen bg-gray-100 text-gray-800">
      {/* Hero Section */}
      <section className="bg-gray-900 bg-opacity-90 text-white py-12 sm:py-20">
        <div className="container mx-auto flex flex-col md:flex-row items-center px-4">
          <div className="w-full md:w-1/2 pr-0 md:pr-8 mb-8 md:mb-0">
            <div className="animate-float">
              <img
                src="https://ussolarsupplier.com/cdn/shop/files/Generac-Home-Generator_EcoGen-15kW_7034_hero.png?v=1712535964"
                alt="Generac Generator"
                className="max-w-full h-auto"
              />
            </div>
          </div>
          <div className="w-full md:w-1/2 text-center md:text-left">
            <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4">
              Power Your Home with Confidence
            </h2>
            <p className="text-lg md:text-xl lg:text-2xl mb-4">
              Authorized Generac Generator Installer in Katy, Texas
            </p>
            <p className="text-base md:text-lg mb-8">
              Don't let power outages catch you off guard.
            </p>
            <a
              href="#"
              className="bg-red-600 text-white px-8 py-4 rounded-lg hover:bg-red-700 transition duration-300 text-xl inline-block"
            >
              Get a Free Quote
            </a>
            <a
              href="/generators"
              onClick={(e) => {
                e.preventDefault();
                setCurrentPage("generators");
              }}
              className="text-red-400 hover:text-red-300 underline ml-2"
            >
              Learn more about our generator solutions
            </a>
          </div>
        </div>
      </section>

      {/* Generator Offer Section */}
      <section className="py-12 sm:py-16 bg-white">
        <div className="container mx-auto text-center px-4">
          <h3 className="text-2xl sm:text-3xl font-bold mb-6 sm:mb-8 text-blue-800">
            Limited Time Offer!
          </h3>
          <div className="bg-gray-100 p-6 sm:p-8 rounded-lg shadow-lg max-w-2xl mx-auto border-2 border-blue-800">
            <p className="text-xl sm:text-2xl font-semibold mb-4 text-gray-800">
              20kW Whole Home Generator
            </p>
            <p className="text-3xl sm:text-4xl font-bold text-red-600 mb-4">
              $8,999 Installed
            </p>
            <p className="mb-6 text-gray-600">
              Includes standard installation and 5-year warranty
            </p>
            <a
              href="#"
              className="bg-blue-800 text-white px-6 py-3 rounded-lg hover:bg-blue-900 transition duration-300"
            >
              Claim This Offer
            </a>
          </div>
        </div>
      </section>

      {/* Veteran-Owned Section */}
      <section className="bg-gray-100 py-12">
        <div className="container mx-auto text-center px-4">
          <h3 className="text-2xl sm:text-3xl font-bold mb-4 text-blue-800">
            Proudly Veteran-Owned
          </h3>
          <p className="text-lg sm:text-xl mb-6 text-gray-600">
            Serving our community with the same dedication we served our country
          </p>
          <div className="flex justify-center space-x-2">
            {[1, 2, 3, 4, 5].map((star) => (
              <Star
                key={star}
                fill="currentColor"
                className="text-red-600 w-6 h-6 sm:w-8 sm:h-8"
              />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
